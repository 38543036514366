<template>
  <div class="drag-stroke-to-img-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @click.native="nextPage"
    ></PageButton>
    <div class="game-content">
      <img
        src="@/assets/img/03-Backgrounds/background-practice.svg"
        alt=""
        class="bg-img"
      />
      <Star
        :totalStarNumber="chineseList.length"
        :currentIndex="answersfound"
        v-if="!isReviewGame"
      />

      <div class="game-box">
        <div class="background">
          <div class="game-area">
            <img
              ref="imgBox"
              class="puzzle-bg-img"
              id="puzzlebackground"
              :src="bgImg"
              :style="{ width: imgWidth + 'px' }"
            />
            <div
              class="map-area"
              :style="{
                width: imgWidth + 1 + 'px',
                height: imgWidth + 1 + 'px',
              }"
            >
              <div class="map-box">
                <div
                  class="col-item"
                  v-for="(item, index) in mapList"
                  :key="index + 'col'"
                >
                  <div
                    class="row-item"
                    v-for="(ele, indexSon) in item"
                    :key="indexSon + 'row'"
                    :class="{
                      noPointerEvents: ele.isAddStyle,
                    }"
                    @drop.stop="
                      dropToImg(
                        $event,
                        JSON.parse(JSON.stringify(ele.type)),
                        ele.id
                      )
                    "
                    @dragover.stop="allowDrop($event)"
                  ></div>
                </div>
              </div>

              <div class="hanzi-img-area">
                <template v-for="(item, index) in chineseList">
                  <transition name="el-fade-in" :key="index">
                    <img
                      :src="item.imageStartURL"
                      alt=""
                      :key="index"
                      v-if="item.isShow && !isReviewGame"
                    />
                  </transition>
                </template>

                <template v-for="(item, index) in chineseList">
                  <transition-group name="el-fade-in" :key="index">
                    <img
                      v-for="(ele, indexSon) in item.strokeImgList"
                      alt=""
                      :key="indexSon"
                      :src="ele.strokeImg"
                      v-show="ele.isShow"
                    />
                  </transition-group>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="strokeList">
          <div class="stroke-box" :class="{ reviewGame: isReviewGame }">
            <div
              v-for="(item, index) in strokeList"
              :key="index"
              class="stroke-item"
            >
              <img
                class="strokeImage"
                :src="item.image"
                draggable="true"
                @dragstart.stop="dragStroke($event, item.type)"
                @drop.stop="Sdrop($event)"
                @dragend="dragendOver"
                :class="{ notShow: strokeType === item.type }"
              />
              <div v-if="isReviewGame" class="percent">
                <span> {{ item.currentSum }} / {{ item.total }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  name: "DragStrokeToImgGame",
  props: {
    chineseList: {
      type: Array,
      default: () => {},
      required: true,
    },
    strokeList: {
      type: Array,
      default: () => {},
      required: true,
    },
    mapList: {
      type: Array,
      default: () => {},
      required: true,
    },
    bgImg: {
      type: String,
      required: true,
    },
    answersfound: {
      type: Number,
      required: true,
    },
    isReviewGame: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Star,
    PageButton,
  },
  data() {
    return {
      strokeType: null,
      isLastStep: false,
      isShowStroke: false,
      dispearIndexList: [],
      currentId: 0,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 1,
            endNumberRange: 100,
          },
        ],
      },

      imgDom: null,
      imgWidth: null,
      dropToImgSocketInfo: {},
      dragStrokeSocketInfo: {},
      dragoverStrokeSocketInfo: {},

      currentHanziStrokeTypeList: [],
    };
  },
  watch: {
    dropToImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004501,
          data: { value },
          text: "DragStrokeToImgGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragStrokeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004502,
          data: { value },
          text: "DragStrokeToImgGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragoverStrokeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004503,
          data: { value },
          text: "DragStrokeToImgGame dropover socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      this.imgDom = this.$refs.imgBox;
      this.imgWidth = parseInt(
        Math.ceil(this.imgDom.getBoundingClientRect().width)
      );

      this.imgDom.getBoundingClientRect().width = 55;
    }, 50);
    this.$bus.$on("dropStrokeEvent", ({ type, id }) => {
      this.handleDragToImg(type, id, true);
    });
    this.$bus.$on("dragToImgEvent", ({ type }) => {
      this.dragStroke("socket", type, true);
    });
    this.$bus.$on("dragoverImgEvent", () => {
      this.dragendOver("socket", true);
    });
    window.addEventListener("resize", this.updateElementPosition);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateElementPosition);
  },
  beforeDestroy() {
    this.$bus.$off("dropStrokeEvent");
    this.$bus.$off("dragToImgEvent");
    this.$bus.$off("dragoverImgEvent");
  },
  methods: {
    updateElementPosition() {
      this.imgDom = this.$refs.imgBox;
      this.imgWidth = parseInt(
        Math.ceil(this.imgDom.getBoundingClientRect().width)
      );
    },
    dragendOver(event, isFromSocket = false) {
      // setTimeout(() => {}, 1500);
      if (!isFromSocket) {
        this.dragoverStrokeSocketInfo = {
          value: Math.random(),
        };
      }
      this.strokeType = null;
    },
    nextPage() {
      if (this.isLastStep) {
        this.$bus.$emit("nextButton", true);
      }
    },
    handleDragToImg(type, id, isFromSocket = false) {
      if (this.answersfound == this.chineseList.length-1) { // have to use -1 because it takes time to propagate
        this.isLastStep = true;
      }
      if (!isFromSocket) {
        this.dropToImgSocketInfo = {
          type,
          id,
          value: Math.random(),
        };
      }
      console.log(this.strokeType, type, id);

      if (type.indexOf(this.strokeType) > -1) {
        this.currentId = id;
        playCorrectSound(true, false);
        this.$emit("handleDropEvent", id, this.strokeType);
      } else {
        playCorrectSound(false);
      }
    },
    dragStroke(event, type, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragStrokeSocketInfo = {
          type,
          value: Math.random(),
        };
      }

      this.strokeType = type;
      console.log(type);
    },
    dropToImg(event, type, id) {
      this.handleDragToImg(type, id);
      event.preventDefault();
    },
    Sdrop(event) {
      event.preventDefault();
    },
    allowDrop(event) {
      event.preventDefault();
    },
  },
};
</script>
<style lang="scss" scoped>
.drag-stroke-to-img-game-container {
  border-radius: 58px;
  position: relative;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .bg-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 58px;
    }
    .game-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      .background {
        flex: 6;
        display: flex;
        justify-content: center;
        align-items: center;
        .game-area {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .puzzle-bg-img {
            width: 70%;
            // height: 65%;
            // object-fit: cover;
            // border-radius: 58px;
            min-width: 400px;

            // border-bottom: 30px solid #cd4c3f;
            // border-bottom-left-radius: 30px;
            // border-bottom-right-radius: 30px;
          }
          .map-area {
            position: absolute;
            margin: auto;
            display: flex;
            top: 0;

            .map-box {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              .col-item {
                z-index: 100;
                flex: 1;
                display: flex;
                flex-direction: column;
                .row-item {
                  flex: 1;
                  // background: greenyellow;
                }
                .noPointerEvents {
                  pointer-events: none;
                }
              }
            }

            .hanzi-img-area {
              width: 100%;
              height: 100%;
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
              }
            }
          }
        }
      }
      .strokeList {
        flex: 3;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .stroke-box {
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .strokeImage {
            cursor: pointer;
            display: inline-block;
            width: 80%;
            -webkit-user-drag: auto;
          }
          .notShow {
            opacity: 0;
          }
        }
        .reviewGame {
          height: 60%;
          display: flex;
          justify-content: space-around;
          .stroke-item {
            width: 80%;
            background: #fff;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .strokeImage {
            width: 50%;
            flex: 1;
          }
          .percent {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #224e96;
            font-size: 4vh;
          }
        }
      }
    }
  }
}
</style>
