var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drag-stroke-to-img-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.nextPage.apply(null, arguments)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("img", {
            staticClass: "bg-img",
            attrs: {
              src: require("@/assets/img/03-Backgrounds/background-practice.svg"),
              alt: "",
            },
          }),
          !_vm.isReviewGame
            ? _c("Star", {
                attrs: {
                  totalStarNumber: _vm.chineseList.length,
                  currentIndex: _vm.answersfound,
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "game-box" }, [
            _c("div", { staticClass: "background" }, [
              _c("div", { staticClass: "game-area" }, [
                _c("img", {
                  ref: "imgBox",
                  staticClass: "puzzle-bg-img",
                  style: { width: _vm.imgWidth + "px" },
                  attrs: { id: "puzzlebackground", src: _vm.bgImg },
                }),
                _c(
                  "div",
                  {
                    staticClass: "map-area",
                    style: {
                      width: _vm.imgWidth + 1 + "px",
                      height: _vm.imgWidth + 1 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "map-box" },
                      _vm._l(_vm.mapList, function (item, index) {
                        return _c(
                          "div",
                          { key: index + "col", staticClass: "col-item" },
                          _vm._l(item, function (ele, indexSon) {
                            return _c("div", {
                              key: indexSon + "row",
                              staticClass: "row-item",
                              class: {
                                noPointerEvents: ele.isAddStyle,
                              },
                              on: {
                                drop: function ($event) {
                                  $event.stopPropagation()
                                  _vm.dropToImg(
                                    $event,
                                    JSON.parse(JSON.stringify(ele.type)),
                                    ele.id
                                  )
                                },
                                dragover: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.allowDrop($event)
                                },
                              },
                            })
                          }),
                          0
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "hanzi-img-area" },
                      [
                        _vm._l(_vm.chineseList, function (item, index) {
                          return [
                            _c(
                              "transition",
                              { key: index, attrs: { name: "el-fade-in" } },
                              [
                                item.isShow && !_vm.isReviewGame
                                  ? _c("img", {
                                      key: index,
                                      attrs: {
                                        src: item.imageStartURL,
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        }),
                        _vm._l(_vm.chineseList, function (item, index) {
                          return [
                            _c(
                              "transition-group",
                              { key: index, attrs: { name: "el-fade-in" } },
                              _vm._l(
                                item.strokeImgList,
                                function (ele, indexSon) {
                                  return _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: ele.isShow,
                                        expression: "ele.isShow",
                                      },
                                    ],
                                    key: indexSon,
                                    attrs: { alt: "", src: ele.strokeImg },
                                  })
                                }
                              ),
                              0
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "strokeList" }, [
              _c(
                "div",
                {
                  staticClass: "stroke-box",
                  class: { reviewGame: _vm.isReviewGame },
                },
                _vm._l(_vm.strokeList, function (item, index) {
                  return _c("div", { key: index, staticClass: "stroke-item" }, [
                    _c("img", {
                      staticClass: "strokeImage",
                      class: { notShow: _vm.strokeType === item.type },
                      attrs: { src: item.image, draggable: "true" },
                      on: {
                        dragstart: function ($event) {
                          $event.stopPropagation()
                          return _vm.dragStroke($event, item.type)
                        },
                        drop: function ($event) {
                          $event.stopPropagation()
                          return _vm.Sdrop($event)
                        },
                        dragend: _vm.dragendOver,
                      },
                    }),
                    _vm.isReviewGame
                      ? _c("div", { staticClass: "percent" }, [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(item.currentSum) +
                                " / " +
                                _vm._s(item.total) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }